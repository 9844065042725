import React from 'react'
import CTACard from '../../CTACard/CTACard'
import RecomendacionCard from '../../../../specific/components/RecomendacionCard/RecomendacionCard'
import Title from '../../Title'
import styles from './index.module.scss'
import { ImageData } from '../../../utils/imagesS3'
import ResponsiveImages from '../../ResponsiveImages/ResponsiveImages'

interface Props {
  title: string
  children: JSX.Element | JSX.Element[]
  textRecomendation?: string
  imageDesktop?: ImageData
  imageMobile?: ImageData
  descriptionCTA?: string
  linkButtonCTA?: string
  textButtonCTA?: string
  ctaLink?: any
}
export default function LayoutLanding({
  title,
  children,
  textRecomendation,
  imageDesktop,
  imageMobile,
  descriptionCTA,
  linkButtonCTA,
  textButtonCTA,
  ctaLink,
}: Props) {
  return (
    <div className={styles.layoutlanding_container}>
      <Title text={title} />
      <ResponsiveImages imageDesktop={imageDesktop} imageMobile={imageMobile} />

      <div className={styles.layoutlanding_inferior}>
        <div className={styles.layoutlanding_left}>{children}</div>
        <div className={styles.layoutlanding_right}>
          {textRecomendation && (
            <RecomendacionCard recomendacion={textRecomendation} />
          )}
          {descriptionCTA && (
            <CTACard
              description={descriptionCTA}
              linkButton={linkButtonCTA}
              textButton={textButtonCTA}
              ctaLink={ctaLink}
            />
          )}
        </div>
      </div>
    </div>
  )
}
