import React from 'react'
import { connect } from 'react-redux'
import styles from './index.module.scss'
import { textWithLocale } from '../../i18n'
import {
  startCitaPrevia,
  startCitaPreviaPatinete,
} from '../../context/actions/citaPreviaActions'
import { navigate } from 'gatsby'
import {
  citaPreviaSteps,
  CitaPreviaEnum,
  patineteCitaSteps,
  PatineteCitaPreviaEnum,
} from '../CitaPrevia/CitaPreviaSteps'
import route from '../../utils/route'

function CtaLink({ data, dispatch }) {
  const goToCitaPrevia = (e) => {
    e.preventDefault()
    const url = document.location.pathname
    dispatch(startCitaPrevia(url))
    navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].route)
  }
  const goToCitaPreviaPatinete = (e) => {
    e.preventDefault()
    const url = document.location.pathname
    dispatch(startCitaPreviaPatinete(url))
    navigate(patineteCitaSteps[PatineteCitaPreviaEnum.MARCA].route)
  }
  switch (data.cta) {
    case 'cita_previa':
      return (
        <a className={styles.link} onClick={goToCitaPrevia} href="#">
          {data[textWithLocale('cta')]}
        </a>
      )
    case 'cita_previa_patinete':
      return (
        <a className={styles.link} onClick={goToCitaPreviaPatinete} href="#">
          {data[textWithLocale('cta')]}
        </a>
      )
    case 'talleres_mecanicos':
      return (
        <a className={styles.link} href={route('talleres.index')}>
          {data[textWithLocale('cta')]}
        </a>
      )
    case 'rodibook':
      return (
        <a
          target="_blank"
          className={styles.link}
          href="https://www.rodibook.com"
          rel="noreferrer">
          {data[textWithLocale('cta')]}
        </a>
      )
    default:
      return null
  }
}

export default connect()(CtaLink)
