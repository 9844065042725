import { graphql } from 'gatsby'

export const query = graphql`
  fragment S3Images on S3Asset {
    Key
    ETag
    parent {
      id
    }
    url
    Metadata {
      alt
      link
    }
  }
`

export interface ImageData {
  Key: string
  url: string
  link: string
  alt: string
}

export interface ImageInputData {
  [k: string]: ImageNodes
}

export interface ImageNodes {
  nodes: ImageNode[]
}

export interface ImageNode {
  Key: string
  url: string
  Metadata?: {
    link?: string
    alt?: string
    alt_es?: string
    alt_ca?: string
  }
}

export function getImages(
  images: ImageNode[],
  locale: string,
  placeholder?: ImageData
): ImageData[] {
  if (images.length) {
    return images.map((n) => {
      const { Metadata } = n
      const altText = decodeURI(
        Metadata.alt ? Metadata.alt : Metadata[`alt_${locale}`]
      )
      return {
        Key: n.Key || '',
        url: n.url || '',
        link: Metadata.link || '',
        alt: altText !== 'undefined' ? altText : '',
      }
    })
  }
  if (placeholder && placeholder.url) return [placeholder]
  return []
}
