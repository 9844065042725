import React from 'react'
import ButtonLink from '../buttons/ButtonLink'
import CtaLink from '../CtaLink/CtaLink'
import styles from './ctacard.module.scss'

interface Props {
  description: string
  linkButton: string
  textButton: string
  ctaLink: any
}

const CTACard = ({ description, linkButton, textButton, ctaLink }: Props) => {
  return (
    <div className={styles.ficha_cta}>
      <p className={styles.cta_descripcion}>{description}</p>
      {ctaLink && <CtaLink data={ctaLink} />}
      {!ctaLink && <ButtonLink to={linkButton}>{textButton}</ButtonLink>}
    </div>
  )
}

export default CTACard
