import React from 'react'
import styles from './responsiveImages.module.scss'

const Image = ({ image, className = null, ...props }) => (
  <img src={image.url} alt={image.alt} className={className} {...props} />
)

interface ImageProp {
  alt: string
  link: string
  url: string
}

interface Props {
  imageDesktop: ImageProp
  imageMobile: ImageProp
}
export default function ResponsiveImages({ imageDesktop, imageMobile }: Props) {
  return (
    <React.Fragment>
      {imageDesktop &&
        (imageDesktop.link ? (
          <a
            href={imageDesktop.link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.image_desktop}>
            <picture>
              <source srcSet={`data:`} media="(max-width: 767px)" />
              <Image image={imageDesktop} />
            </picture>
          </a>
        ) : (
          <picture className={styles.image_desktop}>
            <source srcSet={`data:`} media="(max-width: 767px)" />
            <Image image={imageDesktop} />
          </picture>
        ))}

      {imageMobile &&
        (imageMobile.link ? (
          <a
            href={imageMobile.link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.image_mobile}>
            <source srcSet={`data:`} media="(min-width: 768px)" />
            <Image image={imageMobile} />
          </a>
        ) : (
          <picture className={styles.image_mobile}>
            <source srcSet={`data:`} media="(min-width: 768px)" />
            <Image image={imageMobile} />
          </picture>
        ))}
    </React.Fragment>
  )
}
