import { useContext, useEffect } from 'react'
import { setMenuPrincipal } from '../../context/actions/menuActions'
import AppContext from '../../../../src/common/context/context'
import { MenuPrincipal } from '../../constants/menu'


let selectedMenu: MenuPrincipal = 0

export function getSelectedMenu() {
  return selectedMenu
}

export function setSelectedMenu(menu: MenuPrincipal) {
  selectedMenu = menu
}

export function useSelectedMenu(menu: MenuPrincipal) {
  const { dispatch } = useContext(AppContext)
  setSelectedMenu(menu)
  useEffect(() => {
    dispatch(setMenuPrincipal(menu))
  }, [])
}
