import React from 'react'
import { t } from '../../../../src/common/i18n'
import styles from './recomendacionCard.module.scss'

interface Props {
  recomendacion: string
}

const RecomendacionCard = ({ recomendacion }: Props) => (
  <div className={styles.ficha_recomendacion_container}>
    <h3>{t('marquez')}</h3>
    <p>"{recomendacion}"</p>
  </div>
)

export default RecomendacionCard
