import React from 'react'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import { locale } from '../../../src/common/i18n'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingMotoPt = ({ styles, data }: Props) => {
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image1_mobile] = getImages(
    data[`image1_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image1_desktop] = getImages(
    data[`image1_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image2_mobile] = getImages(
    data[`image2_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image2_desktop] = getImages(
    data[`image2_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <article className={styles.description}>
        <p className={styles.main_description}>
          Nas oficinas Rodi Motor, somos especialistas em manutenção e afinação
          de motas. Oferecemos aos nossos clientes uma vasta seleção de pneus de
          mota com as melhores marcas do mercado: Michelin, Continental,
          Pirelli, Metzeler, Bridgestone, Dunlop...
        </p>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>Existem diferentes tipos de pneus para mota?</h2>
          <p>
            No setor dos pneus para mota, existem diferentes tipos de pneus.
            Estes dividem-se em pneus de estrada, desportivos, mistos ou trail e
            Off-Road.
          </p>
          <p>
            Abaixo, explicamos mais detalhadamente as características de cada
            tipo de pneu para mota:
          </p>
          <ul>
            <li>
              <strong>Pneus de Estrada: </strong>: É o tipo de pneu mais usado.
              É um tipo de pneu fabricado com borrachas duras que se adapta bem
              à circulação a baixas temperaturas e com pequenas ranhuras na sua
              superfície para evacuar a água da chuva presente no asfalto. É
              montado na maioria das motas que vemos todos os dias: 125cc,
              scooter, maxi-scooter, custom e naked.
              <ResponsiveImages
                imageDesktop={image1_desktop}
                imageMobile={image1_mobile}
              />
            </li>
            <li>
              <strong>Pneu Desportivo</strong>: É o tipo de pneu mais usado. É
              um tipo de pneu fabricado com borrachas duras que se adapta bem à
              circulação a baixas temperaturas e com pequenas ranhuras na sua
              superfície para evacuar a água da chuva presente no asfalto. É
              montado na maioria das motas que vemos todos os dias: 125cc,
              scooter, maxi-scooter, custom e naked.
            </li>
            <li>
              <strong>Pneu Misto ou Trail:</strong> São utilizados com motas de
              estilo Trail que são concebidas para serem utilizadas fora do
              asfalto em caminhos de terra pouco exigentes. Esta categoria tem
              muitos modelos com maior ou menor adequação a terrenos Off-Road.
              Destacam-se por ter tacos de borracha marcados que facilitam a
              aderência à pista. São fabricados com borrachas duras, muito
              versáteis no uso e adaptam-se bem a qualquer tipo de temperatura.
            </li>
            <li>
              <strong>Pneus Off-Road</strong>: Destacam-se por ter tacos grandes
              e profundos que se agarram excecionalmente em terrenos moles. São
              ideais para utilização em pistas, caminhos e trilhas. Estes pneus
              não são adequados para utilização em estradas asfaltadas e, na
              maioria dos casos, não são homologados.
              <ResponsiveImages
                imageDesktop={image2_desktop}
                imageMobile={image2_mobile}
              />
            </li>
          </ul>
        </section>
        <section className={styles.section}>
          <h2>
            Quando é que os pneus da moto devem ser substituídos por pneus
            novos?
          </h2>
          <p>
            Tal como nos automóveis, os pneus para mota têm avisos de desgaste
            facilmente percetíveis que indicam quando o pneu atingiu o seu
            limite de uso. Se for necessária uma medição mais precisa, pode ser
            utilizada uma fita métrica para verificar a profundidade das
            ranhuras dos pneus, que devem estar sempre acima da profundidade
            estabelecida pela no Código da Estrada.
          </p>
        </section>
      </article>
    </div>
  )
}

const LandingMoto = ({ ...props }: Props) => <LandingMotoPt {...props} />

export default LandingMoto
