import React from 'react'
import { Link } from 'gatsby'
import styles from './buttonLink.module.scss'

interface Props {
  to: string
  children: string | (string | JSX.Element)[] | JSX.Element
  className?: string
  withAnchor?: boolean
}
const ButtonLink = ({ className, to, children, withAnchor }: Props) => (
  <div className={className || styles.wrapper}>
    {!withAnchor ? (
      <Link to={to}>{children}</Link>
    ) : (
      <a href={to}>{children}</a>
    )}
  </div>
)

export default ButtonLink
